$('.form').on('submit', function(e) {
    e.preventDefault();
    $('.intro__form--error').css('display', 'none');
    $('.intro__form--success').css('display', 'none');
    var $data;
    $data = $(this).serializeArray();
    console.log($data)
    $.ajax({
        url: '/send-mail.php',
        type: 'post',
        data: $data,
        success: function(result) {
            if(result.status === 'success'){
                $('.intro__form--success').css('display', 'block');
                $('input').val('');
            }else{
                $('.intro__form--error').css('display', 'block');

            }

        }
    });
});